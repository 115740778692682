import { IRemittanceBase } from '@/gateway/remittanceBase/model/IRemittanceBase'
import { AnalogueApplyUseCase, IAnalogueApplyUseCase } from '@/usecase/remittance/AnalogueApplyUseCase'
import { IRecipientUseCase, RecipientUseCase } from '@/usecase/recipient/RecipientUseCase'
import { IAnalogueRemittanceRow } from '@/presentation/remittance/model/IAnalogueRemittanceRow'
import { RemittanceGroupDetail } from '@/models/remittance/RemittanceGroupDetail'
import { ReceiveMethodEnum } from '@/enums'
import { RuleItem } from 'async-validator'
import { regexToPatternString } from '@/data/Regex'
import { REQUIRED_INVOICE_USD_AMOUNT } from '@/data/TransferConstants'
import i18n from '@/plugins/i18n'
import { CreateRecipientsParam } from '@/gateway/recipient/CreateRecipients'
import ManageTableOptions from '@/models/forms/ManageTableOptions'

export interface IAnalogueApplyPresentation {
  analogueTableOptions: Dictionary<ManageTableOptions>;
  analogueTableRules: Dictionary<Array<RuleItem>>

  getRemittanceGroupById (id: number): RemittanceGroupDetail | undefined;

  getAnalogueRemittanceRows (remittanceGroups: Array<RemittanceGroupDetail>): Dictionary<Array<IAnalogueRemittanceRow>>;

  getRemittanceBases (): Promise<Array<IRemittanceBase>>;

  deleteRemittanceGroup (remittanceRow: IAnalogueRemittanceRow): void;

  updateRecipient (remittanceRow: IAnalogueRemittanceRow): Promise<boolean>;

  applyRemittances (remittanceRows: Dictionary<Array<IAnalogueRemittanceRow>>): Promise<Array<RemittanceGroupDetail>>;
}

export class AnalogueApplyPresentation implements IAnalogueApplyPresentation {
  private remittanceGroupMap: Map<number, RemittanceGroupDetail>
  private recipientUseCase: IRecipientUseCase
  private useCase: IAnalogueApplyUseCase

  constructor () {
    this.remittanceGroupMap = new Map<number, RemittanceGroupDetail>()
    this.recipientUseCase = new RecipientUseCase()
    this.useCase = new AnalogueApplyUseCase()
  }

  get analogueTableOptions (): Dictionary<ManageTableOptions> {
    return {
      analogue: { type: 'hide' },
      invoice_required: { type: 'hide' },
      invoice: {
        type: 'file'
      },
      swift: {
        type: 'input',
        inputType: 'text',
        placeHolder: 'NEDSZAJJXXX',
        upperCase: true
      },
      line1: {
        type: 'input',
        isButton: true,
        emitHandlerName: 'onClickAddressModal'
      },
      memo_to_recipient: {
        type: 'input',
        label: `${i18n.t('commons.memo_to_recipient')} (${i18n.t('commons.optional')})`,
        isButton: true,
        emitHandlerName: 'onClickAnalogueComment'
      }
    }
  }

  get analogueTableRules (): Dictionary<Array<RuleItem>> {
    return {
      corps_id: [{
        required: true,
        pattern: regexToPatternString('corps_id'),
        message: 'invalid format.'
      }],
      swift: [
        {
          required: true,
          message: i18n.t('commons.type_plz') as string
        },
        {
          pattern: regexToPatternString('swift'),
          message: i18n.t('common.invalid_code') as string
        }
      ],
      line1: [{
        required: true,
        message: 'this field is required'
      }],
      memo_to_recipient: [{
        message: 'this field is required'
      }]
    }
  }

  getRemittanceGroupById (id: number): RemittanceGroupDetail | undefined {
    return this.remittanceGroupMap.get(Number(id))
  }

  getAnalogueRemittanceRows (remittanceGroups: Array<RemittanceGroupDetail>): Dictionary<Array<IAnalogueRemittanceRow>> {
    const remittanceRows: Dictionary<Array<IAnalogueRemittanceRow>> = {}
    let id = 0
    remittanceGroups.forEach(remittanceGroup => {
      this.remittanceGroupMap.set(++id, remittanceGroup)
      const isOverInvoiceLimit = remittanceGroup.usd_amount >= REQUIRED_INVOICE_USD_AMOUNT
      const isInvoiceRequired = isOverInvoiceLimit && !remittanceGroup.file
      const isRequiredAdditionalInfo = remittanceGroup.analogue || isInvoiceRequired
      if (!isRequiredAdditionalInfo) return
      const method = ReceiveMethodEnum[remittanceGroup.recipient.remittance_method_type]
      const countryWithMethod = `${remittanceGroup.recipient.country}_${method}`
      const fullName = `${remittanceGroup.recipient.first_name}${remittanceGroup.recipient.middle_name ?? ' '}${remittanceGroup.recipient.last_name ?? ''}`
      const invoice = remittanceGroup.file
        ? { name: remittanceGroup.file_name || '', value: remittanceGroup.file }
        : { name: '', value: '' }
      const hasCityAndLine1 = remittanceGroup.recipient.line1 && remittanceGroup.recipient.city
      const addressForSwift = hasCityAndLine1 ? `${remittanceGroup.recipient.city.toUpperCase()} ${remittanceGroup.recipient.line1.toUpperCase()}` : ''
      const remittanceRow: IAnalogueRemittanceRow = {
        id: id,
        updatable: remittanceGroup.analogue || isInvoiceRequired,
        analogue: remittanceGroup.analogue,
        corps_id: remittanceGroup.recipient.corp_pid,
        full_name: fullName,
        amount: remittanceGroup.base_amount.balance.toString(),
        base_currency: remittanceGroup.base_amount.currency,
        invoice,
        swift: remittanceGroup.recipient.remittance_method_data.bank_swift,
        line1: addressForSwift,
        memo_to_recipient: remittanceGroup.analogue_comment
      }
      if (!remittanceRows[countryWithMethod]) remittanceRows[countryWithMethod] = []
      remittanceRows[countryWithMethod].push(remittanceRow)
    })
    return remittanceRows
  }

  async getRemittanceBases (): Promise<Array<IRemittanceBase>> {
    const remittanceBases = await this.useCase.getRemittanceBases()
    return remittanceBases
  }

  deleteRemittanceGroup (remittanceRow: IAnalogueRemittanceRow) {
    this.remittanceGroupMap.delete(remittanceRow.id)
  }

  async updateRecipient (remittanceRow: IAnalogueRemittanceRow): Promise<boolean> {
    const remittanceGroup = this.getRemittanceGroupById(remittanceRow.id)
    if (!remittanceGroup) return false
    const recipient = remittanceGroup.recipient
    recipient.remittance_method_data.bank_swift = remittanceRow.swift as string
    const updateRecipientParam: CreateRecipientsParam = {
      list: [recipient]
    }
    const isSuccessResponse = !(await this.recipientUseCase.registerRecipients(updateRecipientParam))
    return isSuccessResponse
  }

  async applyRemittances (remittanceRows: Dictionary<Array<IAnalogueRemittanceRow>>): Promise<Array<RemittanceGroupDetail>> {
    Object.keys(remittanceRows).forEach(countryWithMethod => {
      const remittances: Array<IAnalogueRemittanceRow> = remittanceRows[countryWithMethod]
      remittances.forEach(remittanceRow => {
        const remittanceGroup = this.getRemittanceGroupById(remittanceRow.id)
        if (!remittanceGroup) return
        remittanceGroup.recipient.remittance_method_data.bank_swift = remittanceRow.swift || ''
        remittanceGroup.file = remittanceRow.invoice?.value
        remittanceGroup.file_name = remittanceRow.invoice?.name || remittanceGroup.file_name
        remittanceGroup.analogue_comment = remittanceRow.memo_to_recipient
        this.remittanceGroupMap.set(remittanceRow.id, remittanceGroup)
      })
    })
    return Array.from(this.remittanceGroupMap.values())
  }
}
