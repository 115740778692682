import { IRemittanceBase } from '@/gateway/remittanceBase/model/IRemittanceBase'
import { GetRemittanceBase } from '@/gateway/remittanceBase/GetRemittanceBase'

export interface IAnalogueApplyUseCase {
  getRemittanceBases (): Promise<Array<IRemittanceBase>>
}

export class AnalogueApplyUseCase implements IAnalogueApplyUseCase {
  async getRemittanceBases (): Promise<Array<IRemittanceBase>> {
    const remittanceBases = await GetRemittanceBase.getInstance().request()
    return remittanceBases
  }
}
