
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IRemittanceBase } from '@/gateway/remittanceBase/model/IRemittanceBase'
import { CurrencyEnum } from '@/enums/CurrencyEnum'
import SimpleDataTable from '@/components/shared/SimpleDataTable.vue'
import { formattersMap } from '@/lib/Utils'

@Component({
  components: { SimpleDataTable }
})
export default class AdditionalInfoStandard extends Vue {
  @Prop(Array) remittanceBases!: Array<IRemittanceBase>
  tableRemittanceBases: Array<{ country: string, amount: string }> = []

  created () {
    this.tableRemittanceBases = this.remittanceBases.map(remittanceBase => {
      const amount = formattersMap.number(remittanceBase.limit_amount)
      return {
        country: this.$t(`country.${remittanceBase.country.toLowerCase()}`) as string,
        amount: `${CurrencyEnum[CurrencyEnum.USD]} ${this.$t('commons.more_than_amount', { amount })}`
      }
    })
  }
}
