
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import FormItems from '@/components/shared/FormItems.vue'
import { AnalogueAdditionalProp } from '@/presentation/remittance/model/analogueAdditionalProp'
import {
  AnalogueAdditionalUpdatePresentation,
  IAnalogueAdditionalUpdatePresentation
} from '@/presentation/remittance/AnalogueAdditionalUpdatePresentation'

@Component({
  components: { FormItems }
})
export default class AddressUpdate extends Vue {
  @Prop({
    type: Object,
    required: true
  }) recipientProp!: AnalogueAdditionalProp

  formName = 'swiftAddressForm'
  isReady = false
  presentation: IAnalogueAdditionalUpdatePresentation = new AnalogueAdditionalUpdatePresentation()

  async onSubmit () {
    const isValid = await this.presentation.validate(this.$refs[this.formName])
    if (!isValid) return
    if (!this.presentation.recipient) return
    const isSuccessResponse = await this.presentation.submit()
    const isEmptyComment = !this.presentation.addressField.comment
    const message = isSuccessResponse ? this.$t('notification.registered') : this.$t('notification.errors.server')
    const type = isSuccessResponse ? 'success' : 'error'
    if (!isEmptyComment && isSuccessResponse) {
      this.$message({ type, message: message as string })
    }
    this.recipientProp.recipient = this.presentation.recipient
    this.recipientProp.comment = this.presentation.addressField.comment
    if (isSuccessResponse || isEmptyComment) this.$emit('addressSubmitted', this.recipientProp)
  }

  created () {
    this.presentation.setAnalogueAdditionalProp(this.recipientProp)
    this.isReady = true
  }
}
