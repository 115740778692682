import FormItemsOption from '@/models/forms/FormItemsOption'
import { regexToPatternString } from '@/data/Regex'
import Recipient from '@/models/recipient/Recipient'
import { FormValidator } from '@/presentation/FormValidator'
import { IRecipientUseCase, RecipientUseCase } from '@/usecase/recipient/RecipientUseCase'
import { AnalogueAdditionalProp } from '@/presentation/remittance/model/analogueAdditionalProp'
import i18n from '@/plugins/i18n'
import { CreateRecipientsParam } from '@/gateway/recipient/CreateRecipients'
import FormRule from '@/models/forms/FormRule'

interface AddressField {
  swift: string;
  city: string;
  address: string;
  comment: string;
}

export interface IAnalogueAdditionalUpdatePresentation {
  addressField: AddressField;
  addressForm: Array<FormItemsOption>;
  addressRules: Dictionary<Array<FormRule>>;
  recipient?: Recipient

  setAnalogueAdditionalProp (analogueAdditionalProp: AnalogueAdditionalProp): void;

  validate (form: any): Promise<boolean>;

  submit (): Promise<boolean>;
}

export class AnalogueAdditionalUpdatePresentation implements IAnalogueAdditionalUpdatePresentation {
  private recipientUseCase: IRecipientUseCase
  private formValidator: FormValidator
  private isAddress: boolean = true
  recipient?: Recipient
  addressField: AddressField
  addressForm: Array<FormItemsOption>
  addressRules: Dictionary<Array<FormRule>>

  constructor () {
    this.recipientUseCase = new RecipientUseCase()
    this.formValidator = new FormValidator()
    this.addressField = {
      swift: '',
      city: '',
      address: '',
      comment: ''
    }
    this.addressForm = []
    this.addressRules = {
      city: [
        { required: true, message: 'this field is required' },
        { pattern: regexToPatternString('address_swift'), message: 'english, number, _, \',\' and blank only' }
      ],
      address: [
        { required: true, message: 'this field is required' },
        { pattern: regexToPatternString('address_swift'), message: 'english, number, _, \',\' and blank only' }
      ],
      comment: [
        { pattern: regexToPatternString('address_swift'), message: 'english, number, _, \',\' and blank only' }
      ]
    }
  }

  private setForms () {
    this.addressForm = [
      {
        prop: 'city',
        type: 'text',
        label: 'City',
        placeHolder: 'Seoul',
        size: 'medium',
        hide: !this.isAddress,
        value: undefined
      },
      {
        prop: 'address',
        type: 'text',
        label: 'Address',
        size: 'medium',
        placeHolder: 'GANGNAM-GU, SAMSUNG-DONG',
        hide: !this.isAddress,
        value: undefined
      },
      {
        prop: 'comment',
        type: 'textarea',
        maxlength: 25,
        showLimit: true,
        label: i18n.t('commons.memo_to_recipient_explaination') as string,
        placeHolder: i18n.t('commons.type_english') as string,
        hide: this.isAddress,
        value: undefined
      }
    ]
  }

  setAnalogueAdditionalProp (analogueAdditionalProp: AnalogueAdditionalProp) {
    const { recipient, comment, type } = analogueAdditionalProp
    this.isAddress = type === 'address'
    this.recipient = recipient
    this.addressField = {
      swift: recipient.remittance_method_data.bank_swift || '',
      city: recipient.city || recipient.region || '',
      address: recipient.line1 || '',
      comment: comment || ''
    }
    this.setForms()
  }

  async validate (formName: any): Promise<boolean> {
    const isValid = await this.formValidator.validate(formName)
    return isValid
  }

  private async updateRecipient (): Promise<boolean> {
    if (!this.recipient) return false
    this.recipient.remittance_method_data.bank_swift = this.addressField.swift
    this.recipient.city = this.addressField.city
    this.recipient.line1 = this.addressField.address
    const updateRecipientParam: CreateRecipientsParam = {
      list: [this.recipient]
    }
    const isSuccessResponse = !(await this.recipientUseCase.registerRecipients(updateRecipientParam))
    return isSuccessResponse
  }

  private updateComment (): boolean {
    return !!this.addressField.comment
  }

  async submit (): Promise<boolean> {
    const isSuccessResponse = this.isAddress ? await this.updateRecipient() : this.updateComment()
    return isSuccessResponse
  }
}
