
import Vue from 'vue'
import Component from 'vue-class-component'
import { Getter, Mutation } from 'vuex-class'
import ManageTable from '@/components/shared/ManageTable.vue'
import RecipientList from '@/components/shared/RecipientList.vue'
import { RuleItem } from 'async-validator'
import BasicDialog from '@/components/commons/BasicDialog.vue'
import BaseCurrencyInfo from '@/components/remittance/BaseCurrencyInfo.vue'
import AdditionalInfoStandard from '@/components/remittance/modal/AdditionalInfoStandard.vue'
import {
  AnalogueApplyPresentation,
  IAnalogueApplyPresentation
} from '@/presentation/remittance/AnalogueApplyPresentation'
import { IRemittanceBase } from '@/gateway/remittanceBase/model/IRemittanceBase'
import { IAnalogueRemittanceRow } from '@/presentation/remittance/model/IAnalogueRemittanceRow'
import AddressUpdate from '@/components/remittance/modal/AddressUpdate.vue'
import { AnalogueAdditionalProp } from '@/presentation/remittance/model/analogueAdditionalProp'
import { Utils } from '@/static/Utils'
import ManageTableOptions from '@/models/forms/ManageTableOptions'

@Component({
  components: { AddressUpdate, AdditionalInfoStandard, BaseCurrencyInfo, BasicDialog, RecipientList, ManageTable }
})
export default class ManualApply extends Vue {
  @Getter getAnalogueRemittanceGroups: any
  @Mutation setAnalogueConfirmedRemittanceGroups: any

  presentation: IAnalogueApplyPresentation = new AnalogueApplyPresentation()
  countryWithMethods: Array<string> = []
  remittanceBases: Array<IRemittanceBase> = []
  analogueAdditionalProp?: AnalogueAdditionalProp
  additionalInfoModal: boolean = false
  addressModal: boolean = false
  analogueTransferWithMethod: Dictionary<Array<IAnalogueRemittanceRow>> = {}
  tableOption: Dictionary<ManageTableOptions> = this.presentation.analogueTableOptions
  descriptor: Dictionary<Array<RuleItem>> = this.presentation.analogueTableRules
  hideColumns: Array<string> = ['analogue', 'invoice_required']

  get hasUpdatingTransfers (): boolean {
    let hasUpdating = false
    this.countryWithMethods.forEach(countryWithMethod => {
      const isUpdating: boolean = this.analogueTransferWithMethod[countryWithMethod].some(recipient => {
        return !!recipient.updatable
      })
      hasUpdating = hasUpdating || isUpdating
    })
    return hasUpdating
  }

  additionalModalLabel (): string {
    if (!this.analogueAdditionalProp) return ''
    const name = `${this.analogueAdditionalProp.recipient.last_name} ${this.analogueAdditionalProp.recipient.first_name}`
    const isAddress = this.analogueAdditionalProp.type === 'address'
    return isAddress
      ? `${name} ${this.$t('sheet.field.line1')}`
      : `${name} ${this.$t('commons.memo_to_recipient')}`
  }

  getCountryMethodLabel (countryWithMethod: string): string {
    return Utils.getCountryMethodLabel(countryWithMethod)
  }

  openAdditionalInfoModal () {
    this.additionalInfoModal = true
  }

  deleteRow (countryWithMethod: string, index: number): void {
    const deleteRemittanceRow = this.analogueTransferWithMethod[countryWithMethod][index]
    if (!deleteRemittanceRow) return
    this.presentation.deleteRemittanceGroup(deleteRemittanceRow)
    this.analogueTransferWithMethod[countryWithMethod].splice(index, 1)
  }

  async toggleUpdatable (countryWithMethod: string, index: number, callback: any): Promise<void> {
    const transferRow = this.analogueTransferWithMethod[countryWithMethod][index]
    const isCompleteAction = transferRow.updatable === true
    if (!transferRow.invoice?.name && isCompleteAction) {
      await this.$alert(this.$t('commons.must_invoice_5000usd') as string, {
        showCancelButton: true,
        showConfirmButton: false,
        dangerouslyUseHTMLString: true,
        cancelButtonText: `${this.$t('commons.close')}`,
        center: true
      })
      return
    }
    if (isCompleteAction && transferRow.swift) {
      const isSuccessResponse = await this.presentation.updateRecipient(transferRow)
      if (!isSuccessResponse) return
    }
    transferRow.updatable = isCompleteAction ? !transferRow.updatable : true
    if (callback) callback()
  }

  failValidate () {
    this.$message({ message: this.$t('notification.errors.recheck') as string, type: 'error' })
  }

  addressSubmitted (submitted: AnalogueAdditionalProp) {
    const { recipient, index, countryWithMethod, comment } = submitted
    const remittanceRow: IAnalogueRemittanceRow = this.analogueTransferWithMethod[countryWithMethod][index]
    if (!remittanceRow) return
    remittanceRow.swift = remittanceRow.swift || recipient.remittance_method_data.bank_swift
    remittanceRow.line1 = `${recipient.city} ${recipient.line1}`
    remittanceRow.memo_to_recipient = comment
    const remittanceGroup = this.presentation.getRemittanceGroupById(remittanceRow.id)
    if (!remittanceGroup) return
    remittanceGroup.recipient = recipient
    remittanceGroup.recipient.region = recipient.city
    remittanceGroup.comment = comment
    this.addressModal = false
  }

  openAnalogueAdditionalModal (scope: any, countryWithMethod: string, isAddress: boolean) {
    const row = scope.row as IAnalogueRemittanceRow
    const index = scope.$index as number
    if (!row.id) return
    const remittanceGroup = this.presentation.getRemittanceGroupById(row.id)
    if (!remittanceGroup) return
    this.analogueAdditionalProp = {
      type: isAddress ? 'address' : 'comment',
      recipient: remittanceGroup.recipient,
      index,
      countryWithMethod,
      comment: remittanceGroup.comment as string
    }
    this.addressModal = true
  }

  onClickAddressModal (scope: any, countryWithMethod: string) {
    if (!scope) return
    this.openAnalogueAdditionalModal(scope, countryWithMethod, true)
  }

  onClickCommentModal (scope: any, countryWithMethod: string) {
    if (!scope) return
    this.openAnalogueAdditionalModal(scope, countryWithMethod, false)
  }

  apply (): void {
    if (this.hasUpdatingTransfers) return
    const remittanceGroups = this.presentation.applyRemittances(this.analogueTransferWithMethod)
    this.setAnalogueConfirmedRemittanceGroups(remittanceGroups)
    this.$router.push('/transfer/register')
  }

  async created () {
    this.remittanceBases = await this.presentation.getRemittanceBases()
    this.analogueTransferWithMethod = this.presentation.getAnalogueRemittanceRows(this.getAnalogueRemittanceGroups)
    if (!Object.keys(this.analogueTransferWithMethod).length) {
      this.$alert(`${this.$t('notification.wrong_approach')}`, 'WARNING', {
        type: 'warning',
        center: true
      })
      return this.$router.go(-2)
    }
    this.countryWithMethods = Array(...Object.keys(this.analogueTransferWithMethod))
  }
}
